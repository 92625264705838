import axios from 'axios'
import Vue from 'vue'

/**
 * Login user
 * @param {*} commit
 * @param {*} form
 */
export async function login ({ commit }, form) {
  let data = {}

  await axios
  .post('https://www.api.babyteca.es/auth/authenticate', form)
  .then(function (response) {
    if (response.data.msg.jwt) {
      Vue.prototype.$cookies.set('gb_loggedin', response.data.msg.jwt)
      commit('setUserAuth', response.data.msg.jwt)
    }
    data = response.data
  })
  .catch(function () {
    data = {
      'status': 'ko',
      'msg': 'default_error'
    }
  })
  return data
}

/**
 * Logout user
 * @param {*} commit
 */
export async function logout ({ commit }) {
  Vue.prototype.$cookies.remove('gb_loggedin')
  commit('setUserAuth', {})
}