import Vue from 'vue'
import Vuex from 'vuex'

/**
 * Store modules
 */
import auth from './auth'
import employees from './employees'
import payrolls from './payrolls'
import schools from './schools'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    employees,
    payrolls,
    schools
  }
})

export default store
