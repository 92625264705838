import axios from 'axios'

/**
 * Get employees
 * @param {*} commit
 */
export async function getEmployees ({ commit }, form) {
  form.append('action', 'get')
  await axios
    .post('https://www.api.babyteca.es/api/employees', form)
    .then(function (response) {
      if (response.data.status == 'ok') {
        commit('setEmployees', response.data.msg)
      }
    })
    .catch(function (error) {
      console.log(error)
    })
}
