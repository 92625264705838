import Vue from 'vue'
import VueCookies from "vue-cookies"
import VueToast from 'vue-toast-notification'

import App from './App.vue'
import router from './router'
import store from './store'
import './assets/scss/app.scss'
import 'vue-toast-notification/dist/theme-sugar.css'
import '@quasar/extras/material-icons/material-icons.css'
import langEs from 'quasar/lang/es'
import Quasar from 'quasar/src/vue-plugin.js';import QBtn from 'quasar/src/components/btn/QBtn.js';import QCard from 'quasar/src/components/card/QCard.js';import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';import QDate from 'quasar/src/components/date/QDate.js';import QForm from 'quasar/src/components/form/QForm.js';import QInput from 'quasar/src/components/input/QInput.js';import QTable from 'quasar/src/components/table/QTable.js';
import { LineChart, PieChart } from 'dr-vue-echarts'

Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(VueToast)
Vue.use(LineChart)
Vue.use(PieChart)
Vue.use(Quasar, {
  lang: langEs,
  config: {},
  components: {
    QBtn,
    QCard,
    QCheckbox,
    QDate,
    QInput,
    QTable,
    QForm
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")