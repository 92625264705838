import Vue from 'vue'

export default {
  data() {
    return {
      error: {
        date_format: 'El formato de la fecha no es válido',
        default_error: 'Se ha producido un error',
        email_empty: 'Debes introducir el email',
        email_format: 'El formato del email no es válido',
        field_empty: 'Es obligatorio rellenar este campo',
        id_format: 'El formato del DNI no es válido',
        ko_add_employee: 'Ha habido un problema añadiendo al empleado',
        ko_delete_payroll: 'Ha habido un problema eliminando la nómina',
        ko_download: 'Ha habido un problema descargando la nómina',
        ko_empty_fields: 'Es necesario rellenar todos los campos',
        ko_fire: 'Ha habido un porblema dando de baja al empleado',
        ko_hire: 'Ha habido un porblema dando de alta al empleado',
        ko_login_email: 'El email es incorrecto',
        ko_login_password: 'La contraseña es incorrecta',
        ko_send: 'Ha habido un problema enviando la nómina',
        ko_split: 'Ha habido un problema separando las nóminas',
        ko_update_employee: 'Ha habido un problema modificando al empleado',
        ko_upload: 'Ha habido un problema subiendo las nóminas',
        num_ss_format: 'El formato del nº S.S no es válido',
        password_empty: 'Debes introducir la contraseña',
        phone_format: 'El formato del teléfono no es válido',
        salary_format: 'El formato del sueldo no es válido',
        zip_format: 'El formato del C.P. no es válido'
      },
      success: {
        ok_add_employee: 'Empleado añadido correctamente',
        ok_delete_payroll: 'Nómina eliminada correctamente',
        ok_download: 'Nómina descargada correctamente',
        ok_fire: 'Se ha dado de baja al empleado',
        ok_hire: 'Se ha dado de alta al empleado',
        ok_login: 'Sesión iniciada correctamente',
        ok_send: 'Nómina enviada correctamente',
        ok_split: 'Nóminas separadas correctamente',
        ok_update_employee: 'Empleado modificado correctamente',
        ok_upload: 'Nóminas subidas correctamente'
      },
      payroll_type: {
        sent: 'Enviada',
        uploaded: 'Pendiente'
      }
    }
  },
  methods: {
    translate (status, val) {
      return this[status][val]
    },
    notify (type, message) {
      Vue.$toast.open({
        'type': type,
        'message': this.translate(type, message)
      })
    }
  }
}
