import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import axios from 'axios'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const jwtToken = Vue.prototype.$cookies.get('gb_loggedin')
  const form = new FormData()
  form.append('token', jwtToken)
  axios
  .post('https://www.api.babyteca.es/auth/checkSession', form)
  .then((response) => {
      if (requiresAuth && response.data.status == 'ko') {
        next('/login')
      } else if(response.data.status == 'ok') {
        store.commit('auth/setUserAuth', jwtToken)
        if (to.path === '/login') {
          next('/')
        }
      }
      next()
  })
})

export default router
