export default {
  methods: {
    isMandatory (val) {
      const value = `${val}`
      return value.length > 0
    },

    isNumber (val) {
      return !isNaN(val)
    },

    validEmail (val) {
      const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
      return emailRegex.exec(val) !== null
    },

    validPhone (val) {
      const emailRegex = new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/)
      return emailRegex.exec(val) !== null
    },

    validDate (val) {
      const dateRegex = new RegExp(/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/)
      return dateRegex.exec(val) !== null
    },

    validZip(val) {
      const zipRegex = new RegExp(/^[0-9]{5}$/)
      return zipRegex.exec(val) !== null
    },

    minLength (val, length) {
      return val && val.length >= length
    },

    compareStrings (val1, val2) {
      return val1 === val2
    }
  }
}
