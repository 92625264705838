<template>
  <div id="login" class="login">
    <section class="login-container">
      <div class="login-bg">
        <img class="login-bg__img" src="../../assets/img/login_bg.jpg" />
      </div>
      <div class="login-form">
        <q-form @submit="onLogin" class="login-form__form">
          <h1 class="login-form__title">Gestor de nóminas</h1>
          <q-input
            class="login-form__input"
            v-model="loginForm.email"
            label="Correo electrónico"
            placeholder="contacto@email.com"
            lazy-rules
            :rules="[
              val => isMandatory(val) || translate('error', 'email_empty'),
              val => validEmail(val) || translate('error', 'email_format')
            ]"
          />
          <q-input
            class="login-form__input"
            v-model.trim="loginForm.password"
            label="Contraseña"
            placeholder="******"
            type="password"
            lazy-rules
            :rules="[
              val => isMandatory(val) || translate('error', 'password_empty')
            ]"
          />
          <q-btn :loading="btnLoading" class="login-form__btn" type="submit" color="primary">
            Iniciar sesión
          </q-btn>
        </q-form>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '../../router'
import validateForm from '../../mixins/validateForm'
import dictionary from '../../mixins/dictionary'

export default {
  mixins: [validateForm, dictionary],

  data() {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      btnLoading: false
    }
  },
  methods: {
    ...mapActions('auth', [
      'login'
    ]),
    ...mapGetters('auth', [
      'userAuth'
    ]),
    async onLogin() {
      this.btnLoading = true
      let form = new FormData()
      form.append('email', this.loginForm.email)
      form.append('password', this.loginForm.password)
      let data = await this.login(form)
      if (data.status == 'ok' && this.userAuth) {
        this.notify('success', 'ok_login')
        this.btnLoading = false
        if (router.currentRoute.path === '/login') {
          router.push('/')
        }
      } else if (data.status == 'ko') {
        this.notify('error', data.msg)
      } else {
        this.notify('error', 'default_error')
      }
      this.btnLoading = false
    }
  }
}
</script>
