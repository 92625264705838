import Login from '../views/auth/Login.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/*',
    name: 'Dashboard',
    meta: {
      requiresAuth: true
    },
    redirect: { name: 'SplitPayrolls' },
    component: () => import('../layouts/AppLayout.vue'),
    children: [
      {
        path: '/separar-nominas',
        name: 'SplitPayrolls',
        meta: {
          requiresAuth: true
        },
        component: () => import('../views/dashboard/SplitPayrolls.vue')
      },
      {
        path: '/enviar-nominas',
        name: 'SendPayrolls',
        meta: {
          requiresAuth: true
        },
        component: () => import('../views/dashboard/SendPayrolls.vue')
      },
      {
        path: '/gestionar-nominas',
        name: 'ManagePayrolls',
        meta: {
          requiresAuth: true
        },
        component: () => import('../views/dashboard/ManagePayrolls.vue')
      },
      {
        path: '/gestionar-empleados',
        name: 'ManageEmployees',
        meta: {
          requiresAuth: true
        },
        component: () => import('../views/dashboard/ManageEmployees.vue')
      }
    ]
  }
]

export default routes
