import axios from 'axios'

/**
 * Get payrolls
 * @param {*} commit
 */
export async function getPayrolls ({ commit }, form) {
  form.append('action', 'get')
  await axios
    .post('https://www.api.babyteca.es/api/payrolls', form)
    .then(function (response) {
      if (response.data.status == 'ok') {
        commit('setPayrolls', response.data.msg)
      }
    })
    .catch(function (error) {
      console.log(error)
    })
}

/**
 * Get pending payrolls
 * @param {*} commit
 */
 export async function getPendingPayrolls ({ commit }, form) {
  form.append('action', 'get_pending')
  await axios
    .post('https://www.api.babyteca.es/api/payrolls', form)
    .then(function (response) {
      if (response.data.status == 'ok') {
        commit('setPendingPayrolls', response.data.msg)
      }
    })
    .catch(function (error) {
      console.log(error)
    })
}

